import * as $ from 'jquery';

declare var braintree: any;

// assume these IDs for dom objects
const containerid = '#dropin-container';
const submitbuttonid = '#submit-button';
const processingid = '#processing-message';
const checkoutmessageid = '#checkout-message';

export interface BraintreeConfig {
    clientToken: string;
    urlProcess: string; // '/Registration/PaymentBTProcess.ashx'
    urlReceipt: string; // '/Registration/RegisterReceipt.aspx'
}

export class BraintreePay {
    config: BraintreeConfig;
    submitButton: HTMLInputElement;
    container: HTMLDivElement;
    processing: HTMLDivElement;
    checkoutmessage: HTMLDivElement;

    constructor(config: BraintreeConfig) {
        this.config = config;
        this.submitButton = document.querySelector(submitbuttonid) as HTMLInputElement;
        this.container = document.querySelector(containerid) as HTMLDivElement;
        this.processing = document.querySelector(processingid) as HTMLDivElement;
        this.checkoutmessage = document.querySelector(checkoutmessageid) as HTMLDivElement;
    }

    initalize() {
        const self = this;
        braintree.dropin.create({
            // Insert your tokenization key here
            authorization: this.config.clientToken,
            container: '#dropin-container'
        }, function (createErr, instance) {
            if (createErr) {
                // Handle any errors that might've occurred when creating Drop-in
                console.error(createErr);
                self.showError(createErr);
                return;
            }
            self.submitButton.addEventListener('click', function () {
                self.handleSubmit(instance);
            });
            self.submitButton.disabled = false;
        });
    }

    handleSubmit(instance: any) {
        const self = this;
        self.submitButton.disabled = true;
        self.container.style.display = "none";
        self.processing.style.display = "block";
        instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
            // When the user clicks on the 'Submit payment' button this code will send the
            // encrypted payment information in a variable called a payment method nonce
            if (requestPaymentMethodErr) {
                console.error(requestPaymentMethodErr);
                self.showError(requestPaymentMethodErr);
                return;
            }
            else {
                $.ajax({
                    type: 'POST',
                    url: self.config.urlProcess,
                    data: {
                        'payment_method_nonce': payload.nonce
                    }
                }).done(function (result) {
                    if (result.success) {
                        window.location.href = self.config.urlReceipt;
                    } else {
                        instance.clearSelectedPaymentMethod();
                        self.showError('<strong>Payment Error</strong> ' + result.message);
                    }
                });
            }
        });
    }

    showError(errormsg: string) {
        this.submitButton.disabled = false;
        this.container.style.display = "block";
        this.processing.style.display = "none";
        if (errormsg) {
            this.checkoutmessage.style.display = "block";
            this.checkoutmessage.innerHTML = errormsg;
        }
    }
}